import React from "react"
import "../css/progress.css"
import "animate.css"

const Progress = () => {
  return (
    <div>
      <div className="content">
        <img
          src="/brain.png"
          alt="Fit im Kopf"
          className="image animate__animated animate__rotateIn animate__slow"
        />
        <div className="header1 animate__animated animate__bounceInLeft animate__delay-1s">
          fit im kopf
        </div>
        <div className="header2 animate__animated animate__fadeIn animate__delay-2s">
          Alles über´s Gedächtnis und Geistige Fitness
        </div>
        <div className="animate__animated animate__bounceInUp animate__delay-3s">
          <div className="progessText animate__animated animate__delay-4s animate__pulse animate__repeat-3">
            Neue Webseite in Arbeit ...
          </div>
        </div>
      </div>
    </div>
  )
}

export default Progress
