import React from "react"
import Progress from "../components/Progress"

export default function Home() {
  return (
    <div>
      <Progress />
    </div>
  )
}
